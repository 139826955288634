import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Header.css"; // Ensure you have the styles for any additional custom styling
import { HEADER } from "../../constants/Constants";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <header  className=" text-center py-5 marginTop">
      <div className="container">
        <h1 className="display-4 font-weight-bold header-heading">
          {HEADER.TITLE_1}
        </h1>
        <h1 className="display-4 font-weight-bold text-warning header-heading">
        {HEADER.TITLE_2}
        </h1>
        <p className="lead text-muted mt-4">
          {HEADER.DESCRIPTION}
        </p>
                <Link to="/contact-us" className="primary-btn  p-2 text-coration">
                  Contact us
                </Link>
                  </div>
    </header>
  );
};

export default Header;
