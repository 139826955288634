import React, { useEffect, useRef, useState } from "react";
import "./ContactUs.css";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = () => {
  const text1Ref = useRef(null);
  const text2Ref = useRef(null);
  const [text, setText] = useState(""); // State to store the current text
  const words = ["Kickstart your new project?", "Get in touch"]; // Array of text to display
  const typingSpeed = 100; // Typing speed in milliseconds
  const form = useRef();
  const [formError, setFormError] = useState("");

  useEffect(() => {
    // Add animation classes
    text1Ref.current.classList.add("slide-in-left");
    text2Ref.current.classList.add("slide-in-right");

    // Start auto typing effect
    startTypingAnimation();

    // Clean up
    return () => {
      clearInterval(typingInterval);
    };
  }, []);

  let typingInterval = null; // Interval reference

  const startTypingAnimation = () => {
    let currentWordIndex = 0;
    let currentCharacterIndex = 0;

    typingInterval = setInterval(() => {
      if (currentCharacterIndex <= words[currentWordIndex].length) {
        setText(words[currentWordIndex].slice(0, currentCharacterIndex));
        currentCharacterIndex++;
      } else {
        // Pause before erasing
        setTimeout(() => {
          eraseText();
        }, 1000);
      }
    }, typingSpeed);
  };

  const eraseText = () => {
    let currentWordIndex = 0;
    let currentCharacterIndex = words[currentWordIndex].length;

    clearInterval(typingInterval);
    typingInterval = setInterval(() => {
      if (currentCharacterIndex >= 0) {
        setText(words[currentWordIndex].slice(0, currentCharacterIndex));
        currentCharacterIndex--;
      } else {
        currentWordIndex = (currentWordIndex + 1) % words.length;
        setTimeout(() => {
          startTypingAnimation();
        }, 500);
        clearInterval(typingInterval);
      }
    }, typingSpeed);
  };

  const validateForm = () => {
    const name = form.current.name.value.trim();
    const contactNumber = form.current.contactNumber.value.trim();
    const email = form.current.email.value.trim();
    const message = form.current.message.value.trim();

    if (!name || !contactNumber || !email || !message) {
      setFormError("All fields are required.");
      return false;
    }

    setFormError("");
    return true;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    emailjs
      .sendForm('service_zz99h5k', 'template_s7r6dog', form.current, {
        publicKey: '_wm8XX8quPwN83eI2',
      })
      .then(
        () => {
          toast.success('Your message sent successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          form.current.reset();
        },
        (error) => {
          toast.error('Unfortunately, your message was not sent', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
            
          });
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <>
      <div className="contact-us-bg">
        <div className="d-flex flex-column justify-content-center align-items-center text-center">
          <h2 className="text-center mb-4 contact-heading">Contact Us</h2>
          <h1 ref={text1Ref} className="display-4 font-weight-bold contact-us">
            {text}
          </h1>
          <h1 ref={text2Ref} className="display-4 font-weight-bold contact-us">
            Get in touch
          </h1>
        </div>
      </div>

      <section id="contact-section" className="bg-dark text-light py-5">
        <div className="container">
          <div className="row">
            {/* Contact Form */}
            <div className="col-md-6">
              <form
                id="contactForm"
                data-aos="fade-right"
                data-aos-delay={100}
                ref={form}
                onSubmit={sendEmail}
              >
                {formError && <div className="alert alert-danger">{formError}</div>}
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    required=""
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="contactNumber" className="form-label">
                    Contact Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="contactNumber"
                    name="contactNumber"
                    required=""
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    required=""
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows={3}
                    required=""
                    defaultValue={""}
                  />
                </div>
                <button type="submit" className="btn contactBtn rounded-0">
                  Submit
                </button>
              </form>
            </div>

            <div className="col-md-6  d-none d-md-block">
              <div>
                <h1 className="contact-text">Get in touch with us</h1>
                <ul className="d-flex flex-column">
                  <li className="list-item-spacing">
                    <i className="fas fa-phone me-3" /> +92 (344) 0177289
                  </li>
                  <li className="list-item-spacing">
                    <i className="fas fa-envelope me-3" /> info@innoscripts.com
                  </li>
                  <li className="list-item-spacing">
                    <i className="fas fa-home me-3" /> Johar Town Lahore,
                    Pakistan
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
};

export default ContactUs;
