import React from 'react';
import './AboutUs.css';
import missionImage from '../../assets/mission.jpg';
import visionImage from '../../assets/vision.jpg';

const AboutUs = () => {
  return (
    <>
      <div className="about-us-bg">
        <div className="d-flex flex-column justify-content-center align-items-center text-center text-white">
          <h2 className="text-center mb-4 contact-heading">About Us</h2>
          <h1 className="display-4 font-weight-bold contact-us">Welcome to Our Company</h1>
        </div>
      </div>

      <div className="about-us-section">
        <div className="mission-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6" data-aos="fade-right">
                <div className="card mission-card">
                  <div className="card-body d-flex flex-column justify-content-center">
                    <h2 className="card-title mission-heading">Our Mission</h2>
                    <p className="card-text lead">
                      Since our inception in 2024, we have been unwavering in our commitment to delivering exceptional value to our clients. Our mission is to bridge the gap between cutting-edge technology and business solutions, providing our engineering talent with unique opportunities to engage with the business aspects of software development. We are proud to lead in adopting emerging technologies and trends, ensuring our clients benefit from the most advanced and innovative solutions available.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-none d-md-block" data-aos="fade-left">
                <div className="image-wrapper">
                  <img src={missionImage} alt="Mission" className="img-fluid h-100" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="vision-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6" data-aos="fade-right" data-aos-delay={100}>
                <div className="card vision-card">
                  <div className="card-body">
                    <h2 className="card-title vision-heading">Our Vision</h2>
                    <p className="card-text lead">
                      Since our inception in 2024, we have been unwavering in our commitment to delivering exceptional value to our clients. Our mission is to bridge the gap between cutting-edge technology and business solutions, providing our engineering talent with unique opportunities to engage with the business aspects of software development. We are proud to lead in adopting emerging technologies and trends, ensuring our clients benefit from the most advanced and innovative solutions available.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-none d-md-block" data-aos="fade-left" data-aos-delay={100}>
                <img src={visionImage} alt="Vision" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
