import React from 'react';
import { Link } from 'react-router-dom';
import './NotFoundPage.css'; // Ensure you create a corresponding CSS file for styling

const NotFoundPage = () => {
  return (
    <div className="not-found-container bg-dark">
      <h1 className="not-found-heading">404</h1>
      <p className="not-found-message">Oops! The page you are looking for does not exist.</p>
      <Link to="/" className="primary-btn not-found-button" style={{textDecoration:'none'}}>Go Back Home</Link>
    </div>
  );
}

export default NotFoundPage;
